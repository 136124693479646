import React from 'react';
import { Form } from 'react-bootstrap';

const EditBoardsTable = (props: any) => {
  const { probes, isSelected, selectedProbes, setSelectedProbes } = props;
  const handleChangeProbes = (e: any) => {
    const { value } = e.target;
    if (isSelected(selectedProbes, value)) {
      setSelectedProbes(selectedProbes?.filter((val: any) => val !== value));
    } else {
      setSelectedProbes([...selectedProbes || [], value]);
    }
  };
  return (
    <table className="table table-responsive-sm">
      <thead>
        <tr>
          <th>
            <span className="text-nowrap" style={{ fontWeight: 500 }}>Name</span>
          </th>
          <th />
          <th>
            <span className="text-nowrap" style={{ fontWeight: 500 }}>Alias</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {probes?.map((item: any) => (
          <tr key={`probe_${item.probe_name}`}>
            <td>
              <Form.Check
                defaultChecked={isSelected(selectedProbes, item.probe_name)}
                defaultValue={item.probe_name}
                id={`groups-probe.${item.probe_name}`}
                key={`groups-probe.${item.probe_name}`}
                inline
                aria-label="Select"
                onClick={handleChangeProbes}
              />
            </td>
            <td>{item.probe_name}</td>
            <td>{item.probe_alias}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EditBoardsTable;
