import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type ResetComponentRequestData = {
  probeName: string;
};

export default function useResetComponent(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ probeName }: ResetComponentRequestData) => axios.patch(
    `/pcs/api/components/${probeName}/reset`
  ).then(({ data }) => data), options);
}
