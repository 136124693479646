import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';

const EditBoardsTable = (props: any) => {
  const { sortedBoards, isSelected, selectedBoards, setSelectedBoards } = props;
  const handleChangeBoards = (e: any) => {
    const { value } = e.target;
    if (isSelected(selectedBoards, value)) {
      setSelectedBoards(selectedBoards?.filter((val: any) => val !== value));
    } else {
      setSelectedBoards([...selectedBoards || [], value]);
    }
  };
  const arrays = Object.keys(sortedBoards) || [];

  return (
    <table className="table table-responsive-sm">
      <thead>
        <tr role="row">
          <th>
            <span className="text-nowrap" style={{ fontWeight: 500 }}>Array</span>
          </th>
          <th colSpan={2}>
            <span className="text-nowrap" style={{ fontWeight: 500 }}>Board Path</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {arrays && arrays.map((simArray: string) => (
          <Fragment key={`array_${simArray}`}>
            {sortedBoards[simArray].map((item: any, index: number) => (
              <tr key={item.board_path}>
                <td style={{ width: '30%' }}>{index === 0 && simArray}</td>
                <td style={{ width: '5px' }}>
                  <Form.Check
                    defaultChecked={isSelected(selectedBoards, item.board_path)}
                    defaultValue={item.board_path}
                    id={`groups-board.${item.board_path}`}
                    key={`groups-board.${item.board_path}`}
                    inline
                    aria-label="Select"
                    onClick={handleChangeBoards}
                  />
                </td>
                <td>{item.board_path}</td>
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default EditBoardsTable;
