import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type RebootComponentRequestData = {
  probeName: string;
};

export default function useRebootComponent(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ probeName }: RebootComponentRequestData) => axios.patch(
    `/pcs/api/components/${probeName}/reboot`
  ).then(({ data }) => data), options);
}
