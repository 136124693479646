import React, { useEffect, useMemo, useState } from 'react';

import PageTitle from 'components/PageTitle';
import {
  Button, Card, CardColumns, Col, Container, Row, CardGroup, Accordion, Form,
} from 'react-bootstrap';
import _, { isArray } from 'lodash';
import clsx from 'clsx';

import AddDeviceModal from './AddDeviceModal';
import AddLocationModal from './AddLocationModal';
import AddProbeModal from './AddProbeModal';
import EditDeviceModal from './EditDeviceModal';

import useAlerts from '../../../hooks/useAlerts';
import useConfirm from '../../../hooks/useConfirm';
import useGetProbeLocations from '../../../hooks/services/probe/location/useGetProbeLocations';
import useGetProbes from '../../../hooks/services/probe/useGetProbes';
import { IProbeNew } from '../../../context/backendData/interfaces';
import useDeleteProbeLocation from '../../../hooks/services/probe/location/useDeleteProbeLocation';
import EditProbeModal from './EditProbeModal';
import useGetPowerControllers from '../../../hooks/services/powerControl/useGetPowerControllers';
import useRebootComponent from '../../../hooks/services/powerControl/useRebootComponent';
import useResetComponent from '../../../hooks/services/powerControl/useResetComponent';
import useHardRestart from '../../../hooks/services/powerControl/useHardRestart';

const ProbeManagement = () => {
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);
  const { showAlert } = useAlerts();
  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const { data: locations, refetch: getLocations } = useGetProbeLocations();
  const { data: probes, refetch: getProbes } = useGetProbes();
  const { data: powerControllers } = useGetPowerControllers();

  // group probes by location
  const probesFixedLocation = probes?.map((probe: IProbeNew) => (
    !probe.country_iso ? { ...probe, country_iso: 'UNK' } : probe));
  const fixedLocations = [
    ...locations || [],
    { country_name: 'Unknown location', country_iso: 'UNK' },
  ];
  const [search, setSearch] = useState('');
  const filteredProbes = useMemo(() => _.chain(probesFixedLocation)
    .filter((p: any) => !search || (Object.values(p).filter((x: any) => {
      if (isArray(x) && x[0]) {
        return (Object.values(x[0]).filter((y: any) => String(y)
          .toLowerCase().includes(search.toLowerCase())).length !== 0);
      }
      return (String(x).toLowerCase().includes(search.toLowerCase()));
    }).length !== 0)).groupBy(
      (probe) => probe.country_iso
    ).value(),
  [probesFixedLocation, search]);

  // add location
  const [showLocationModal, setShowLocationModal] = useState(false);
  // add probe within given location
  const [showAddProbeModal, setShowAddProbeModal] = useState(false);
  const [updatedLocation, setUpdatedLocation] = useState('');
  const handleAddProbe = (e: any) => {
    setShowAddProbeModal(true);
    setUpdatedLocation(e.target.value);
  };
  // edit probe modal
  const [showEditProbeModal, setShowEditProbeModal] = useState(false);
  const [editedProbe, setEditedProbe] = useState<IProbeNew | undefined>();
  // add / edit device for given probe name
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const [updatedProbe, setUpdatedProbe] = useState('');
  const [updatedDevice, setUpdatedDevice] = useState('');
  const [isDisabledReset, setIsDisabledReset] = useState<Array<{ name: any, time: any }>>();
  const [isDisabledReboot, setIsDisabledReboot] = useState<Array<{ name: any, time: any }>>();
  const [isDisabledRebootDevice, setIsDisabledRebootDevice] = useState<Array<{ serial: any
  , time: any }>>();
  const [isDisabledHardRestart, setIsDisabledHardRestart] = useState<Array<{ name: any
  , time: any }>>();

  const disableButton = (button: any, probeName: any) => {
    if (button === 'reset') {
      if (!isDisabledReset?.find((val: any) => val
        .name === probeName)) {
        setIsDisabledReset([...isDisabledReset || [], {
          name: probeName,
          time: Date.now()
        }]);
      }
    } else if (button === 'reboot') {
      if (!isDisabledReboot?.includes(probeName)) {
        setIsDisabledReboot([...isDisabledReboot || [], {
          name: probeName,
          time: Date.now()
        }]);
      }
    } else if (button === 'rebootDevice') {
      if (!isDisabledRebootDevice?.includes(probeName)) {
        setIsDisabledRebootDevice([...isDisabledRebootDevice || [], {
          serial: probeName,
          time: Date.now()
        }]);
      }
    } else if (button === 'hardRestart') {
      if (!isDisabledHardRestart?.includes(probeName)) {
        setIsDisabledHardRestart([...isDisabledHardRestart || [], {
          name: probeName,
          time: Date.now()
        }]);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const time = Date.now();
      if (isDisabledReset?.filter((item) => time > item.time + (30000))) {
        setIsDisabledReset(isDisabledReset?.filter((item) => time < (item.time + 30000)));
      }
      if (isDisabledReboot?.filter((item) => time > item.time + (30000))) {
        setIsDisabledReboot(isDisabledReboot?.filter((item) => time < (item.time + 30000)));
      }
      if (isDisabledRebootDevice?.filter((item) => time > item.time + (30000))) {
        setIsDisabledRebootDevice(isDisabledRebootDevice
          ?.filter((item) => time < (item.time + 30000)));
      }
      if (isDisabledHardRestart?.filter((item) => time > item.time + (60000))) {
        setIsDisabledHardRestart(isDisabledHardRestart
          ?.filter((item) => time < (item.time + 60000)));
      }
      getProbes();
    }, 5000);
    return () => clearInterval(interval);
  }, [isDisabledReset, isDisabledReboot, isDisabledRebootDevice, isDisabledHardRestart]);

  const handleAddDevice = (e: any) => {
    setShowAddDeviceModal(true);
    setUpdatedProbe(e.target.value);
  };

  const [deleteLocation] = useDeleteProbeLocation(
    {
      onSuccess: () => {
        setMessage('location has been deleted');
        getLocations();
      },
      onError: () => setErrMessage('unable to delete location'),
    },
  );
  // add confirm modal for delete location
  const confirm = useConfirm();
  const handleDeleteLocation = async ({ country_iso }: any) => {
    if (filteredProbes[country_iso]) {
      setErrMessage('The location has assigned probes, you cannot delete it!');
    } else {
      confirm({
        body: 'Are you sure to delete location?',
        onOk: () => deleteLocation({ country_iso }),
        title: 'Delete location',
      });
    }
  };

  const handleSearchProbes = (e: any) => {
    setSearch(e.target.value ?? '');
    setActiveTab(undefined);
  };

  const [resetComponent] = useResetComponent(
    {
      onError: () => setErrMessage('Power Cycle of probe failed'),
    },
  );

  const handleResetComponent = (probeName: any) => {
    resetComponent({ probeName });
    disableButton('reset', probeName);
  };

  const [rebootComponent] = useRebootComponent(
    {
      onError: () => setErrMessage('Probe reboot failed'),
    },
  );

  const handleRebootComponent = (probeName: any) => {
    rebootComponent({ probeName });
    disableButton('reboot', probeName);
  };

  const [rebootDevice] = useRebootComponent(
    {
      onError: () => setErrMessage('Device restart failed'),
    },
  );

  const handleRebootDevice = (probeName: any) => {
    rebootDevice({ probeName });
    disableButton('rebootDevice', probeName);
  };

  const [hardRestart] = useHardRestart(
    {
      onError: () => setErrMessage('Arduino reboot failed'),
    },
  );

  const handleHardRestart = (probeName: any) => {
    hardRestart({ probeName, parameters: {} });
    disableButton('hardRestart', probeName);
  };

  return (
    <Container>
      <section className="mb-4" data-test="numerical-data">
        <Row>
          <Col md={12}>
            <PageTitle>Probe management</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Form.Group className="ml-auto p-2 mb-3" controlId="searchProbes">
              <Form.Control
                type="text"
                placeholder="Search probes"
                onChange={(e: any) => {
                  handleSearchProbes(e);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={7} style={{ marginLeft: 'auto', marginRight: '0', display: 'flex' }}>
            <Button
              id="add-new-location"
              variant="primary"
              className="ml-auto p-1 mb-3"
              onClick={() => setShowLocationModal(true)}
            >
              <span className="ml-3 mr-3">Add new location</span>
            </Button>
          </Col>
        </Row>
        {showLocationModal
          && (
            <AddLocationModal
              onHide={() => setShowLocationModal(false)}
              show={showLocationModal}
              setMessage={setMessage}
              setErrMessage={setErrMessage}
              getLocations={getLocations}
            />
          )}
        {showAddProbeModal
          && (
            <AddProbeModal
              onHide={() => setShowAddProbeModal(false)}
              show={showAddProbeModal}
              setErrMessage={setErrMessage}
              setMessage={setMessage}
              location={updatedLocation}
            />
          )}
        {showEditProbeModal
          && (
            <EditProbeModal
              onHide={() => setShowEditProbeModal(false)}
              onDeleteProbeCancel={() => setShowEditProbeModal(true)}
              show={showEditProbeModal}
              setErrMessage={setErrMessage}
              setMessage={setMessage}
              probeData={editedProbe}
              getProbes={getProbes}
              locations={locations}
            />
          )}
        {showAddDeviceModal
          && (
            <AddDeviceModal
              onHide={() => setShowAddDeviceModal(false)}
              show={showAddDeviceModal}
              setErrMessage={setErrMessage}
              setMessage={setMessage}
              probeName={updatedProbe}
              getProbes={getProbes}
            />
          )}
        {showEditDeviceModal
          && (
            // device data must be refactored when BE exists
            <EditDeviceModal
              onHide={() => setShowEditDeviceModal(false)}
              onDeleteDeviceCancel={() => setShowEditDeviceModal(true)}
              show={showEditDeviceModal}
              probeName={updatedProbe}
              deviceData={updatedDevice}
              getProbes={getProbes}
            />
          )}
        {fixedLocations
          && fixedLocations
            .filter((loc: any) => (
              (search && filteredProbes[loc.country_iso]?.length) || !search))
            .sort((a: any, b: any) => a.country_name.localeCompare(b.country_name))
            .map((location: any) => (
              <Accordion key={`${location.country_iso}_acordion`} defaultActiveKey="-1" activeKey={search ? location.country_iso : activeTab || undefined}>
                <CardGroup key={`${location.country_iso}_card`}>
                  <Card key={location.country_name} style={{ border: 0 }}>
                    <Card.Body style={{ padding: '5px' }}>
                      <Card.Header
                        style={{
                          fontSize: '1.2em',
                          fontWeight: 'inherit',
                          margin: '2px !important',
                          padding: '5px',
                          background: '#efefef',
                          border: '1px solid #B2B2B2',
                        }}
                        className="d-flex"
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={location.country_iso}
                          style={{ width: '80%' }}
                          onClick={() => {
                            setActiveTab(
                              activeTab === location.country_iso
                                ? undefined
                                : location.country_iso
                            );
                          }}
                        >
                          <span className="mr-3 align_left">{location.country_name}</span>
                        </Accordion.Toggle>
                        <Button
                          id="delete-location"
                          variant="secondary"
                          style={{ background: 'white' }}
                          className="ml-auto p-2"
                          onClick={() => (
                            handleDeleteLocation({ country_iso: location.country_iso })
                          )}
                        >
                          <span className="ml-3 mr-3">Delete location</span>
                        </Button>
                      </Card.Header>
                      <Accordion.Collapse eventKey={location.country_iso}>
                        <CardColumns className="mt-2">
                          {filteredProbes[location.country_iso]
                            && filteredProbes[location.country_iso]
                              .sort((a: any, b: any) => a.probe_alias
                                .localeCompare(b.probe_alias))
                              .map(
                                ((probe: any) => (
                                  <Card key={probe.probe_name}>
                                    <Card.Body>
                                      <Card.Title className="d-flex">
                                        <i className={clsx('icon icon-record icon-solid', { 'text-success': probe.status === 'online', 'text-danger': probe.status === 'offline' })} style={{ margin: 'auto' }} />
                                        <span className="align_left">
                                          {`${probe.probe_name} - ${probe.probe_alias}`}
                                        </span>
                                        <Button
                                          variant="light"
                                          className="text-button-link ml-auto pr-2 pl-2"
                                          key={`${probe.probe_name}_edit`}
                                          id={`edit-device_${probe.probe_name}`}
                                          onClick={() => {
                                            setShowEditProbeModal(true);
                                            setEditedProbe(probe);
                                          }}
                                        >
                                          {/* todo replace with icons? */}
                                          edit/delete
                                        </Button>
                                      </Card.Title>
                                      <Card.Text>
                                        {(powerControllers?.find((val: any) => val
                                          .name === probe.probe_name)) && (
                                          <>
                                            <Row style={{ float: 'right' }}>
                                              <Button
                                                variant={(isDisabledReset?.some((val: any) => val
                                                  .name === probe.probe_name)) ? 'secondary' : 'outline-primary'}
                                                size="sm"
                                                key="power-cycle"
                                                id={`power-cycle_${probe.probe_name}`}
                                                value={probe.probe_name}
                                                disabled={(isDisabledReset?.some((val: any) => val
                                                  .name === probe.probe_name))}
                                                onClick={() => {
                                                  handleResetComponent(probe.probe_name);
                                                }}
                                              >
                                                Power Cycle
                                              </Button>
                                              <Button
                                                variant={(isDisabledReboot?.some((val: any) => val
                                                  .name === probe.probe_name))
                                                  || (isDisabledReset?.some((val: any) => val
                                                    .name === probe.probe_name)
                                                    || probe.status === 'offline') ? 'secondary' : 'outline-primary'}
                                                size="sm"
                                                id={`reboot_${probe.probe_name}`}
                                                value={probe.probe_name}
                                                disabled={(isDisabledReboot?.some(
                                                  (val: any) => val
                                                    .name === probe.probe_name
                                                ))
                                                  || (isDisabledReset?.some((val: any) => val
                                                    .name === probe.probe_name))
                                                  || probe.status === 'offline'}
                                                onClick={() => {
                                                  handleRebootComponent(probe.probe_name);
                                                }}
                                              >
                                                Reboot
                                              </Button>
                                            </Row>
                                          </>
                                        )}
                                        <br /><hr />
                                        {probe?.devices && probe?.devices.map((device: any) => (
                                          <>
                                            <Row>
                                              <Col className="col-lg-8">
                                                <Button
                                                  variant="secondary"
                                                  key={`${device.name}_edit`}
                                                  id={`edit-device_${device.name}`}
                                                  onClick={() => {
                                                    setShowEditDeviceModal(true);
                                                    setUpdatedProbe(probe.probe_name);
                                                    setUpdatedDevice(device);
                                                  }}
                                                >
                                                  {/* eslint-disable-next-line no-nested-ternary */}
                                                  {device.name} ({device.type === 'Android' ? ((device.status === 'online' && device.stf_status === 'online' && probe.status === 'online') ? 'online' : 'offline') : ((device.status === 'online' && probe.status === 'online') ? 'online' : 'offline')})
                                                </Button>
                                              </Col>
                                            </Row>
                                            <Row style={{ float: 'right' }}>
                                              {(device.type === 'Android' || device.type === 'iOS') && (
                                              <Button
                                                variant={(isDisabledHardRestart?.some(
                                                  (val: any) => val
                                                    .name === probe.probe_name
                                                )) ? 'secondary' : 'outline-primary'}
                                                size="sm"
                                                id={`hardRestart_${probe.probe_name}`}
                                                value={probe.probe_name}
                                                disabled={(isDisabledHardRestart?.some(
                                                  (val: any) => val
                                                    .name === probe.probe_name
                                                ))
                                                        || probe.status === 'offline'}
                                                onClick={() => {
                                                  handleHardRestart(probe.probe_name);
                                                }}
                                                style={{ float: 'right' }}
                                              >
                                                Power Cycle
                                              </Button>
                                              )}
                                              {(powerControllers?.find((val: any) => val
                                                .name === device.serial)) && (
                                                  <Button
                                                    variant={(isDisabledRebootDevice?.some(
                                                      (val: any) => val
                                                        .serial === device.serial
                                                    )) ? 'secondary' : 'outline-primary'}
                                                    size="sm"
                                                    id={`reboot_${probe.probe_name}`}
                                                    value={probe.probe_name}
                                                    disabled={(isDisabledRebootDevice?.some(
                                                      (val: any) => val
                                                        .serial === device.serial
                                                    ))
                                                      || (isDisabledReboot?.some((val: any) => val
                                                        .name === probe.probe_name))
                                                      || (isDisabledReset?.some((val: any) => val
                                                        .name === probe.probe_name))
                                                      || probe.status === 'offline'
                                                      || device.status === 'offline'}
                                                    onClick={() => {
                                                      handleRebootDevice(device.serial);
                                                    }}
                                                    style={{ float: 'right' }}
                                                  >
                                                    Reboot
                                                  </Button>
                                              )}
                                            </Row>
                                            <br />
                                          </>
                                        ))}
                                        {probe?.devices.length === 0 && (
                                        <>
                                          <Button
                                            variant="outline-info"
                                            key="add-device"
                                            id={`add-device_${probe.probe_name}`}
                                            value={probe.probe_name}
                                            onClick={handleAddDevice}
                                          >
                                            Add device
                                          </Button>
                                          <br /><br />
                                        </>
                                        )}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                )),
                              )}
                          <Button
                            key={`${location.country_iso}_add-probe`}
                            id={`${location.country_iso}_add-probe`}
                            variant="primary"
                            value={location.country_iso}
                            onClick={handleAddProbe}
                          >
                            Add new probe
                          </Button>
                        </CardColumns>
                      </Accordion.Collapse>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Accordion>
            ))}
      </section>
    </Container>
  );
};

export default ProbeManagement;
